/** @jsx jsx */
import React, { useEffect, useState } from "react"
import { jsx, Flex } from "theme-ui"
import { graphql } from "gatsby"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Columns from "../components/columns"
import ExternalLink from "../components/external-link"
import * as enka from "../components/enka"
import Player from "../components/@showcase-analytics-player"
import Avatar from "../components/@showcase-analytics-avatar"
import Ranking from "../components/@showcase-analytics-ranking"
import UseLocalStorage from "../hooks/use-local-storage"

const Page = () => {
	const {t} = useTranslation()
		, [error, setError] = useState(false)
		, [data, setData] = useState((()=>{
			const analytics = (new UseLocalStorage(`enka`)).get(`analytics`)
			if(!analytics || Date.now() > analytics.timestamp + 1000*60*(60*24+1))
				return false
			return analytics
		})())
	useEffect(()=>{ //データの初期ロード
		if(!data) {
			enka.fetchAnalytics().then(data => {
				if(data) { //取得した
					setData(data)
					setError(false)
				} else {
					setError(true)
				}
			})
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])
	return (
		<Layout
			title={t(`Showcase Analytics`)}
			bottom={<Flex sx={{
				flexDirection: `column`,
				alignItems: `center`,
			}}>
				<div sx={{ mb: 1, color: `textLow`, }}>
					<div>
						<Link to="/showcase" sx={{
							color: `link`,
							textDecoration: `none`,
						}}>{t(`Showcase`)}</Link>
						{t(` users are the population of the statistics.`)}
					</div>
					<div>
						{t(`Statistics are updated daily.`)}
					</div>
				</div>
				<enka.EnkaNetworkLink />
			</Flex>}
		>
			{data ? <>
				<Columns pcReverse={true}>
					<Avatar data={data.avatarInfo} t={t} />
					<Player data={data.playerInfo} t={t} />
				</Columns>
				<Ranking data={data.avatarInfo} t={t} />
			</> : error ?
				<Flex sx={{
					flexDirection: `column`,
					alignItems: `center`,
				}}>
					<div sx={{
						fontSize: [4,4,5],
						textAlign: `center`,
					}}>{t(`Error`)}</div>
					<div sx={{mt: 3, mb: 4,}}>
						{t(`1. Try reloading the page.`)}<br/>
						<br/>{t(`If you report it on our Discord, we might be able to deal with it sooner.`)}<br/>
						<ExternalLink
							sxProp={{textDecoration:`none`,}}
							href="https://discord.gg/5bKYuCcmfu"
						>
							{t(`Discord invitation link`)}
						</ExternalLink>
					</div>
				</Flex>
			:
				<div sx={{height: `300px`}}></div>
			}
		</Layout>
	)
}

export default Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "characters", "weapons", "showcase", "showcase-analytics", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
