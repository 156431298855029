/** @jsx jsx */
import { useEffect, useRef, useState } from "react"
import { jsx, Flex, Divider, Input } from "theme-ui"
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	ArcElement,
	BarElement,
	Tooltip,
	Legend,
} from "chart.js"
import { Doughnut, Bar } from "react-chartjs-2"

import UseLocalStorage from "../hooks/use-local-storage"

ChartJS.register(
	CategoryScale,
	LinearScale,
	ArcElement,
	BarElement,
	Tooltip,
	Legend
)

type PlayerProps = {
	data: any
	t: any
}

const Player = ({data, t}:PlayerProps) => {
	const log = useRef((new UseLocalStorage(`enka`)).get(`log`))
		, [num, setNum] = useState(log.current?.playerInfo //古いデータに対応
			? log.current.playerInfo.finishAchievementNum
			: (data.achievementTop/1.5).toFixed(0))
		, theTop = n => {
			let count = 0
			for(let i=0; i<n; i++) {
				count += data.achievementArr[i]
			}
			return ((data.count-count)/data.count*100).toFixed(0)
		}
	const arArr = []
	data.levelArr.forEach((e,i)=>{
		if(e !== 0) arArr[String(i)] = e
	})
	const arData = {
			labels: Object.keys(arArr),
			datasets: [{
				data: Object.values(arArr),
				backgroundColor: [
					...Array(Object.keys(arArr).length - 6).fill("#0000"),
					"#0ffa", "#0f0a", "#ff0a", "#f80a", "#f00a", "#408a",
				],
			}],
		}
		, saData = {
			labels: Object.keys(data.towerArr),
			datasets: [{
				data: data.towerArr,
				backgroundColor: [
					...Array(data.towerArr.length - 5).fill("#0000"),
					"#0f0a", "#ff0a", "#f80a", "#f00a", "#408a",
				],
			}],
		}
		, doughnutOptions = {
			datasets: {
				doughnut: {
					borderColor: `#fffb`,
					radius: "90%",
					hoverOffset: 8,
				},
			},
			plugins: {
				legend: {
					display: false,
				},
			},
		}
		, acData = {
			labels: Object.keys(data.achievementArr100).map(e => (e*100)+"~"),
			datasets: [{
				data: data.achievementArr100,
			}],
		}
		, barOptions = {
			datasets: {
				bar: {
					backgroundColor: `#0000`,
					borderColor: `#fff`,
					borderWidth: 2,
					borderRadius: 6,
				},
			},
			plugins: {
				legend: {
					display: false,
				},
			},
			scales: {
				x: {
					ticks: {
						color: `#fff`,
					},
					border: {
						color: `#fff`,
						width: 2,
					},
					grid: {
						drawOnChartArea: false,
					},
				},
				y: {
					display: false,
					beginAtZero: true,
				},
			},
		}
	return (<div>
		<h2 sx={{
			mt: `0 !important`,
			mb: `0 !important`,
		}}>{t(`Player Data`)}</h2>
		<div sx={{
			textAlign: `right`,
			color: `textLow`,
		}}>{data.count} {t(`users`)}</div>
		<Flex sx={{mx: `auto`, "&>*": { width: `100%`, },}}>
			<div>
				<div sx={{
					textAlign: `center`,
					fontWeight: `bold`,
				}}>{t(`Adventure Rank`)}</div>
				<Doughnut
					options={doughnutOptions}
					data={arData}
				/>
				<div sx={{
					textAlign: `center`,
				}}>
					<span sx={{color: `#0ffd`}}>■</span>55&nbsp;
					<span sx={{color: `#0f0d`}}>■</span>56&nbsp;
					<span sx={{color: `#ff0d`}}>■</span>57<br/>
					<span sx={{color: `#f80d`}}>■</span>58&nbsp;
					<span sx={{color: `#f00d`}}>■</span>59&nbsp;
					<span sx={{color: `#408`}}>■</span>60
				</div>
			</div>
			<div>
				<div sx={{
					textAlign: `center`,
					fontWeight: `bold`,
				}}>{t(`Spiral Abyss Floor`)}</div>
				<Doughnut
					options={doughnutOptions}
					data={saData}
				/>
				<div sx={{
					textAlign: `center`,
				}}>
					<span sx={{color: `#0f0d`}}>■</span>8&nbsp;
					<span sx={{color: `#ff0d`}}>■</span>9&nbsp;
					<span sx={{color: `#f80d`}}>■</span>10<br/>
					<span sx={{color: `#f00d`}}>■</span>11&nbsp;
					<span sx={{color: `#408`}}>■</span>12
				</div>
			</div>
		</Flex>
		<div sx={{
			mt: 4, mb: `12px`,
			textAlign: `center`,
			fontWeight: `bold`,
		}}>{t(`Achievement`)}</div>
		<Flex sx={{
			mx: `auto`,
			">*": { my: `auto`, },
		}}>
			<div sx={{ width: `100%`, }}>
				<Bar
					options={barOptions}
					data={acData}
				/>
			</div>
			<div sx={{
				width: `50%`,
				textAlign: `center`,
				lineHeight: 1,
				">div": {
					mb: `.2em`,
				},
			}}>
				<div>{t(`Top Player`)}</div>
				<div sx={{
					fontSize: `1.5em`,
				}}>{data.achievementTop}</div>
				<Divider sx={{my: `.5em`, mx: `1em !important`,}} />
				{num < data.achievementTop ?
					<div>{t(`The Top`)} {theTop(num)}%</div>
				:
					<div>{t(`You are Top`)}</div>
				}
				<Input
					type="number"
					defaultValue={num}
					onChange={e=>setNum(e.target.value)}
					title="Achievement Number"
					sx={{
						fontSize: `1.5em`,
						width: `70%`,
						mx: `auto`,
						p: 0,
						textAlign: `center`,
					}}
				/>
			</div>
		</Flex>
	</div>)
}

export default Player
