/** @jsx jsx */
import React from "react"
import { jsx, Flex } from "theme-ui"

import Avatar from "./avatar"
import * as enka from "./enka"
import { useStar } from "../hooks/use-characters"

type RankingProps = {
	data: any
	t: any
}

const Ranking = ({data, t}:RankingProps) => {
	const sorted = data
		.map(e => { return {
			name: enka.name(e.avatarId),
			count: e.count,
		} })
		.sort((a,b) => b.count - a.count)
	return (<div sx={{
		maxWidth: [`480px`, `100%`],
		mx: `auto`,
	}}>
		<h2 sx={{
			mb: `0 !important`,
		}}>{t(`Popularity Ranking`)}</h2>
		<div sx={{
			fontSize: [1,1,2],
			color: `textLow`,
		}}>{t(`The ranking of farmed characters displayed in the profile.`)}</div>
		<Flex sx={{
			flexWrap: `wrap`,
			">div": {
				m: 2,
				width:[`calc(100% / 5 - 16px)`, `calc(100% / 10 - 16px)`]
			},
		}}>
			{sorted.map((e,i)=>(<React.Fragment key={i}>
				<Avatar
					folder="character"
					name={e.name}
					star={useStar(e.name)}
					text={e.count}
				/>
			</React.Fragment>))}
		</Flex>
	</div>)
}

export default Ranking
